//---------------------------------
//===---------------------------===
// Carousels
//===---------------------------===
//---------------------------------
const hasReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)"),
	carouselOptions = Object.assign({
		autoRotate: hasReducedMotion ? false : true,
		rotationSpeed: 10000,
	})

function rotateCarousel(carousel, options) {
	if (options.autoRotate != true) {
		const items = carousel.querySelectorAll(".carousel__item"),
			controls = carousel.querySelectorAll(".carousel-control")
		let currentItem = carousel.querySelector(
				".carousel__item.js-carousel-active"
			),
			currentItemIndex = parseInt(currentItem.getAttribute("data-index"))

		controls[currentItemIndex].classList.remove("js-carousel-active")
		currentItemIndex = (currentItemIndex + 1) % items.length

		const nextItem = carousel.querySelector(
			`.carousel__item[data-index="${currentItemIndex}"]`
		)

		currentItem.classList.remove("js-carousel-active")
		controls[currentItemIndex].classList.add("js-carousel-active")
		nextItem.classList.add("js-carousel-active")
	}
}

if (document.querySelector(".carousel")) {
	const carousels = document.querySelectorAll(".carousel-wrapper")

	carousels.forEach(carousel => {
		const items = carousel.querySelectorAll(".carousel__item")

		items.forEach((item, index) => {
			item.setAttribute("data-index", index)
		})

		setInterval(
			() => rotateCarousel(carousel, carouselOptions),
			carouselOptions.rotationSpeed
		)

		const imageSelects = carousel.querySelectorAll(".carousel-control")

		imageSelects.forEach((selector, index) => {
			selector.addEventListener("click", () => {
				if (!selector.classList.contains("js-carousel-active")) {
					const target = selector.getAttribute("data-target"),
						item = document.querySelector(`#${target}`),
						activeItem = document.querySelector(
							".carousel__item.js-carousel-active"
						),
						activeControl = document.querySelector(
							".carousel-control.js-carousel-active"
						)

					activeItem.classList.remove("js-carousel-active")
					activeControl.classList.remove("js-carousel-active")

					selector.classList.add("js-carousel-active")

					item.classList.add("js-carousel-active")
				}
			})
		})
	})
}
