//---------------------------------
//===---------------------------===
// Javascript
//===---------------------------===
//---------------------------------

//===-----------------===
// Reusable functions
//===-----------------===
if (document.querySelector("#pf__year")) {
	document.querySelector("#pf__year").innerHTML = new Date().getFullYear()
}

const classToggle = function (elements, elementClass) {
	elements.forEach(element => {
		if (!element.classList.contains(`${elementClass}`)) {
			element.classList.add(`${elementClass}`)
		} else {
			element.classList.remove(`${elementClass}`)
		}
	})
}

const alertStatus = function (alert, alertMessage) {
	document.getElementById(alert).innerHTML = `<p>${alertMessage}</p>`
}

//===-----------------===
// Navigation
//===-----------------===
function maintainFocus(container) {
	const focusableElementsSelector =
			'[href], [tabindex]:not([tabindex="-1"]), input, a > a, a',
		focusableElements = container.querySelectorAll(focusableElementsSelector),
		firstFocusableElement = focusableElements[0],
		lastFocusableElement = focusableElements[focusableElements.length - 1],
		navLinks = container.querySelectorAll("li")

	navLinks.forEach(navLink => {
		navLink.addEventListener("keyup", e => {
			const isTabPressed = e.key === "Tab" || e.keyCode === 9

			if (!isTabPressed) {
				return
			}
			if (e.shiftKey) {
				if (document.activeElement === firstFocusableElement) {
					document.querySelector(".ph__nav__btn").focus()
					e.preventDefault()
				}
			} else {
				if (
					document.activeElement === navLink ||
					document.activeElement === lastFocusableElement
				) {
					container.classList.add("js-nav-active")
					document.querySelector(".ph__nav__btn").focus()
					e.preventDefault()
				}
			}
		})
	})
}

if (document.querySelector(".ph__nav__btn")) {
	const menuBtn = document.querySelector(".ph__nav__btn"),
		nav = document.querySelector(".ph__nav")

	menuBtn.addEventListener("click", () => {
		classToggle([menuBtn, nav], "js-nav-active")
		maintainFocus(nav)

		if (nav.classList.contains("js-nav-active")) {
			alertStatus("menu-status", "Menu is open")
		} else {
			alertStatus("menu-status", "Menu is closed")
		}
	})
}

if (document.querySelector(".overlay")) {
	const overlay = document.querySelector(".overlay"),
		menuBtn = document.querySelector(".ph__nav__btn"),
		nav = document.querySelector(".ph__nav")

	overlay.addEventListener("click", () => {
		classToggle([menuBtn, nav], "js-nav-active")
	})
}
