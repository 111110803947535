//===-----------------===
// Accordions
//===-----------------===

const accordionToggle = (accordionClass, accordionBtnClass, closeMenu) => {
	const accordions = document.querySelectorAll(accordionClass)
	accordions.forEach(accordion => {
		const accordionBtn = accordion.querySelector(accordionBtnClass)

		accordionBtn.addEventListener("click", () => {
			let accordionLabel = ""

			if (accordionBtn.innerText) {
				accordionLabel = accordionBtn.innerText
			} else {
				accordionBtn.getAttribute("data-title")
			}
			//===-------//
			// Removing aria-expanded and class from all other accordions
			//-------===//
			accordions.forEach(otherAccordions => {
				if (otherAccordions !== accordion) {
					otherAccordions.classList.remove("js-accordion-active")
					otherAccordions
						.querySelector(accordionBtnClass)
						.setAttribute("aria-expanded", "false")
				}
			})

			//===-------//
			// Ensuring that the aria-expanded will swap if the same accordion that is open is clicked
			//-------===//
			if (accordionBtn.getAttribute("aria-expanded") === "true") {
				accordionBtn.setAttribute("aria-expanded", "false")
				alertStatus("accordion-status", `${accordionLabel} is collapsed`)
			} else {
				accordionBtn.setAttribute("aria-expanded", "true")
				alertStatus("accordion-status", `${accordionLabel} is expanded`)
			}

			classToggle([accordion], "js-accordion-active")
		})
	})
}

//===-----------------===
// Accordion toggles
//===-----------------===
if (document.querySelector(".accordion__wrapper")) {
	accordionToggle(".accordion", ".accordion__btn", false)
}
